// frontend/src/components/utils/CheckboxInputNL.js
import React from 'react';

const CheckboxInputNL = ({ id, checked, onChange, label }) => {
    return (
        <div style={{ marginTop: '4vh', marginLeft: '25vw', display: 'flex', alignItems: 'flex-start' }}>
            <input
                type="checkbox"
                id={id}
                checked={checked}
                onChange={onChange}
                className="checkbox-input"
                style={{ marginRight: '5vw', marginTop: '1vh', width: '5vw', height: '3vh'}}
            />
            <label htmlFor={id} style={{textAlign: 'left', marginTop: '1vh'}}>
                {label}
            </label>
        </div>
    );
};

export default CheckboxInputNL;