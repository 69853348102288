import React, {useContext, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import '../../assets/styles/RegistrationStyles.css'
import logoMail from '../../assets/imgs/EMail.png';
import logoAddress from '../../assets/imgs/Address.png';
import logoContact from '../../assets/imgs/Contact.png';
import logoPinpoint from '../../assets/imgs/PinPoint.png';
import Header from "../../components/Header";
import RectFrame from "../../components/frames/RectFrame";
import TextInput from "../../components/utils/TextInput";
import CheckboxInput from "../../components/utils/CheckboxInput";
import SubmitButton from "../../components/utils/SubmitButton";
import { doubleOptIn, checkCredentials, createUser, handleUserRegistration } from "../../components/utils/Credential";
import IDContext from "../../components/utils/IDContext";
import useEventExists from "../../components/utils/useEventExists";
import Cookies from "js-cookie";
import CheckboxInputNL from "../../components/utils/CheckboxInputNL";

export let verification = '';
export let user;

const Registration = () => {
    const { eventId } = useParams();
    useEventExists(eventId);
    let navigate = useNavigate();
    const {userID, setUserID} = useContext(IDContext);

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [street, setStreet] = useState('');
    const [streetNumber, setStreetNumber] = useState('');
    const [zip, setZIP] = useState('');
    const [place, setPlace] = useState('');
    const [agree, setAgree] = useState(false);
    const [wantsNewsletter, setWantsNewsletter] = useState(false);
    const isFormValid = email && name && street && place && agree;

    const navigateToVerification = async () => {
        try {
            await checkCredentials(name, email, street + " " + streetNumber, zip, place);
            user = createUser(name, email, street + " " + streetNumber, zip, place, wantsNewsletter);
            console.log('User:', user);
            let id = handleUserRegistration(user, eventId);
            setUserID(id);
            console.log('User ID:', id);
            doubleOptIn(user.email).then((response) => {verification = response});
            Cookies.set("email", email, { expires: 7 });
            navigate(`/${eventId}/verification`);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <Header />
            <div className="rect-frame-content">
                <RectFrame />
                <div className="registration-content">
                    <div className="reg-container">
                        <label className="text-registration">Registrierung</label>
                    </div>

                    <div className="flex-container">
                        <img src={logoMail} alt="E-Mail Address icon" className="icon"/>
                        <TextInput inputType="info" placeholder="E-Mail" value={email}
                                   onChange={(e) => setEmail(e.target.value)}/>
                    </div>

                    <div className="flex-container">
                        <img src={logoContact} alt="Contact icon" className="icon"/>
                        <TextInput inputType="info" placeholder="Vorname und Nachname" value={name}
                                   onChange={(e) => setName(e.target.value)}/>
                    </div>

                    <div className="flex-container">
                        <img src={logoAddress} alt="Address icon" className="icon"/>
                        <TextInput inputType="street" placeholder="Strasse"
                                   value={street} onChange={(e) => setStreet(e.target.value)}/>

                        <TextInput inputType="streetNumber" placeholder="Nr."
                                   value={streetNumber} onChange={(e) => setStreetNumber(e.target.value)}/>
                    </div>

                    <div className="flex-container">
                        <img src={logoPinpoint} alt="PinPoint icon" className="icon"/>
                        <TextInput inputType="zip" placeholder="PLZ"
                                   value={zip} onChange={(e) => setZIP(e.target.value)}/>

                        <TextInput inputType="place" placeholder="Ort"
                                   value={place} onChange={(e) => setPlace(e.target.value)}/>
                    </div>
                    <div className="checkbox-container">
                        <CheckboxInput id="termsAgree" checked={agree} onChange={(e) => setAgree(e.target.checked)}
                                       label="Einverstanden mit der"
                                       link={`/${eventId}/privacy-policy`}/>
                    </div>
                    <div className="checkbox-container">

                        <CheckboxInputNL id="termsAgree"  onChange={(e) => setWantsNewsletter(e.target.checked)}
                                         label="Newsletter abonnieren"/>
                    </div>
                    <SubmitButton isFormValid={isFormValid} onClick={navigateToVerification}>Teilnehmen</SubmitButton>
                </div>
            </div>
        </div>
    );
}

export default Registration;