import { balloonColors } from "../../assets/colors/Colors";

export const balloon = {
    name: "",
    owner: "",
    color: balloonColors.standard,
    pattern: "",
    patternName: "",
    colorPattern:"",
    colorPatternName:"",
    air: ""
}

export const bgColors = {
    "Green"     : "#7DA038",
    "LightBlue" : "#62B4EA",
    "DarkBlue"  : "#114884"
};

export const user = {
    name: "",
    email: "",
    street: "",
    postalCode: "",
    location: "",
    events: [], // Assuming events are not captured in the form, initialize as an empty list
};

export const createCSV= (owners, eventName) => {
    // Define the headers for the CSV file
    const headers = ["Rang", "Name", "Adresse", "E-Mail", "Newsletter-Anmeldung","OWNERid"];
    
    // Map over the owners to create CSV rows
    const rows = owners.map(owner => [
        `"${owner.rank}"`,
        `"${owner.name}"`,
        `"${owner.address}"`,
        `"${owner.email}"`,
        `"${owner.wantsNewsletter}"`,
        `"${owner.id}"`
    ].join(';'));
  
    // Combine headers and rows
    const csvContent = [
      headers.join(';'), // Add the headers as the first row
      ...rows            // Add all other rows
    ].join('\n');

    console.log(csvContent);
    startCSVDownload(csvContent,eventName);
    return csvContent;
}

function startCSVDownload(csv,eventName) {
    const blob = new Blob([csv], {type: 'application/csv'});
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    // Get the current date in YYYY-MM-DD format
    const currentDate = new Date().toISOString().split('T')[0];

    // Generate the filename with event name and current date
    a.download = `${eventName}_${currentDate}_UserData.csv`;
    a.href = url;
    a.style.display = "none";

    document.body.appendChild(a);

    a.click();
    a.remove();

    URL.revokeObjectURL(url);
}
