import React, {useState, useEffect, useRef} from 'react';
import {fetchWithHeaders, baseURL} from "../../../components/utils/api";
import { createCSV } from '../../../components/utils/utils';


const Explorer = () => {
    const [events, setEvents] = useState([]);
    const [buttonDisplay, setButtonDisplay] = useState([]);
    const [copied, setCopied] = useState(null);

    const fetchUserdata = async (id) => {
        return fetchWithHeaders(`/api/event_table/${id}`)
            .then(res => res.json())
            .then(data => {
                const balloons = data.balloons;
                const owners = [];
                balloons.forEach(balloon => {
                    const owner = balloon.owner;

                    if (owner != null && typeof owner === 'object') {
                        owner.rank = balloon.rank;
                        owner.id = balloon.id;
                    } else {
                        console.log("Owner is not an object");
                        console.log(owner);
                    }
                    if (owner != null) {
                        owners.push(owner);
                    }
                });
                console.log(owners);
                return owners;
            });
    }
    
    const handleExport = (id, eventName) => {
      //fetchUserdata(id).then(data => createCSV(data, eventName));

        fetchWithHeaders(`/api/event_table/export/${id}/csv`)
            .then(response => response.text())
            .then(csvContent => {
                const blob = new Blob([csvContent], { type: 'text/csv' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                const currentDate = new Date().toISOString().split('T')[0];
                a.download = `${eventName}_${currentDate}_UserData.csv`;
                a.href = url;
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();
                a.remove();
                URL.revokeObjectURL(url);
            })
            .catch(error => console.error('Error fetching CSV:', error));
    };

  const fetchEventData = () => {
    fetchWithHeaders("/api/event_table/")
    .then(response => response.json())
    .then(data => {
        setEvents(data);
        const displayText = data.map(data => data.started ? "Stop": "Start");
        setButtonDisplay(displayText);
    })
  }

    useEffect(() => {
        // Fetch data immediately on page load
        fetchEventData();

        // Set up interval to fetch data every 5 seconds
        const intervalId = setInterval(() => {
            fetchEventData();
        }, 5000); // 5000 milliseconds = 5 seconds

        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    const handleEventStart = async (index, eventId) => {
        // toggle the display string for event button
        setButtonDisplay(buttonText => ({
            ...buttonText,
            [index]: buttonText[index] === 'Start' ? 'Stop' : 'Start',
        }));

        fetchWithHeaders(`/api/event_table/${eventId}/toggle_event`, { method: "PATCH" })
        .then(response => {
            if (response.ok) {
                return response.text();  // Use .text() instead of .json() to handle empty responses
            }
            throw new Error('Failed to toggle event');
        })
        .then(() => {
            console.log("Event toggled successfully");
        })
        .catch(error => {
            console.error("Error toggling event:", error);
        });
    }

    const generateUserLink = (eventId, row) => {
        const baseURL = process.env.REACT_APP_API_URL;
        const link = `${baseURL}/${eventId}`;
        navigator.clipboard.writeText(link)
            .then ( () => {
                setCopied(row);
                setTimeout(() => setCopied(null), 2000);
            });
    }

  return (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table
                className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead
                    className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border-b-2 border-gray-200">
                <tr>
                    <th scope="col" className="px-6 py-3">
                        Eventname
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Datum
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Kanton
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Event Id
                    </th>
                </tr>
                </thead>
                <tbody>
                {events.map((event, index) => (
                    <tr key={index}
                        className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <th scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                            {event.name}
                        </th>
                        <td className="px-6 py-4">
                            {event.date}
                        </td>
                        <td className="px-6 py-4">
                            {event.canton}
                        </td>
                        <td className="px-6 py-4">
                            {event.id}
                        </td>
                        <td>
                            <button
                                onClick={() => generateUserLink(event.id, index)}
                                className="text-white font-bold py-2 px-4 rounded"
                                style={{
                                    backgroundColor: 'dimgrey',
                                    width: '100px',
                                    height: '35px',
                                    textAlign: 'center',
                                }}>
                            Copy Link
                            </button>
                            {copied === index && <p style={{ color: 'green' }}>Link copied!</p>}
                        </td>
                        <td>
                            <button
                                className="text-white font-bold py-2 px-4 rounded"
                                onClick={() => handleExport(event.id, event.name)}
                                style={{
                                    backgroundColor: 'dimgrey',
                                    width: '100px',
                                    height: '35px',
                                    textAlign: 'center',
                                }}>
                            User Data
                            </button>
                        </td>
                        <td>
                            <button
                                className="text-white font-bold py-2 px-4 rounded"
                                id="eventButton"
                                onClick={() => handleEventStart(index, event.id)}
                                style={{
                                    backgroundColor: 'dimgrey',
                                    width: '100px',
                                    height: '35px',
                                    textAlign: 'center',
                                }}>
                            {buttonDisplay[index]}
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

        </div>
    );

}

export default Explorer